<template>
  <div>
    <div class="about">
      <img src="../assets/img/contact_bg.svg" alt="" class="contact_bg" />
      <div class="floor1 clearfix">
        <div class="title1">About us</div>
        <div class="title2">About us</div>
        <img src="../assets/img/why-mib.svg" alt="" class="floor1_img" />
      </div>
      <div class="floor2">
        <div class="title">What is MIB for SACCOs?</div>
        <div class="txt">
          MIB for SACCOs is a product of MIB African Solutions Limited, a
          technology company with a robust team that builds and supports SACCO
          management systems.<br />
          <br /><br />
          Our team, with extensive experience in the financial and technology
          industry, has developed a SACCO management system that enables SACCOs
          to achieve their vision, by providing optimization of technology at
          affordable cost.
        </div>
      </div>
      <div class="floor3">
        <div class="content">
          <div class="title">Our mission</div>
          <div class="txt">
            Our goal is for our products and services to transform management of
            SACCOs and contribute to better services to the SACCO members.
          </div>
          <div class="title mt70">Our vision</div>
          <div class="txt">
            Empower every SACCO and its members to achieve more.
          </div>
        </div>
        <img src="../assets/img/MaskGroup.svg" alt="" class="women" />
      </div>
    </div>
    <div class="h5">
      <img src="../assets/img/h5/Group_161.png" alt="" class="bg" />
      <div class="floor2">
        <div class="title">What is MIB for SACCOs?</div>
        <div class="txt">
          MIB for SACCOs is a product of MIB African Solutions Limited, a
          technology company with a robust team that builds and supports SACCO
          management systems.<br />
          <br /><br />
          Our team, with extensive experience in the financial and technology
          industry, has developed a SACCO management system that enables SACCOs
          to achieve their vision, by providing optimization of technology at
          affordable cost.
        </div>
      </div>
      <div class="floor3">
        <div class="content">
          <img src="../assets/img/MaskGroup.svg" alt="" class="women" />
          <div class="title">Our mission</div>
          <div class="txt">
            Our goal is for our products and services to transform management of
            SACCOs and contribute to better services to the SACCO members.
          </div>
          <div class="title mt30">Our vision</div>
          <div class="txt">
            Empower every SACCO and its members to achieve more.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$store.commit("SetActivePage", "aboutus");
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .about {
    position: relative;
    width: 100%;
    min-width: 1200px;
    .contact_bg {
      width: 94.3%;
      position: absolute;
      left: 0;
      top: -160px;
      z-index: 1;
    }
    .floor1 {
      position: relative;
      z-index: 2;
      margin-bottom: 200px;
      .title1 {
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 80px;
        color: #ffffff;
        padding: 216px 0 0 18.4%;
        float: left;
        position: relative;
        z-index: 3;
      }
      .title2 {
        font-style: normal;
        font-weight: bold;
        font-size: 130px;
        height: 172px;
        color: #ffffff;
        opacity: 0.15;
        position: absolute;
        z-index: 2;
        left: 12.5%;
        top: 170px;
      }
      .floor1_img {
        width: 40.5%;
        float: right;
        margin-right: 3.85%;
        margin-top: -30px;
      }
    }
    .floor2 {
      width: 1260px;
      margin: 0 auto 250px;
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 37px;
        text-align: center;
        color: #cc3e91;
        margin-bottom: 90px;
      }
      .txt {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 40px;
        text-align: center;
        color: #4d4d4d;
      }
    }
    .floor3 {
      width: 89.6%;
      height: 800px;
      margin: 0 auto 80px;
      background: #622b83;
      border-radius: 44px;
      position: relative;
      .content {
        position: relative;
        width: 46.5%;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 10.8%;
        z-index: 2;
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 45px;
          line-height: 37px;
          color: #cc3e91;
          &.mt70 {
            margin-top: 70px;
          }
        }
        .txt {
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          line-height: 40px;
          color: #fefefe;
          margin-top: 40px;
        }
      }
      .women {
        position: absolute;
        z-index: 1;
        width: 35.2%;
        right: 2.7%;
        bottom: 0;
      }
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .about {
    display: none;
  }
  .h5 {
    .bg {
      width: 100%;
      display: block;
      display: block;
      margin-top: -86px;
      z-index: 2;
      margin-bottom: 30px;
    }
    .floor2 {
      width: 94%;
      margin: 20px auto;
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        color: #cc3e91;
        margin-bottom: 24px;
      }
      .txt {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #4d4d4d;
      }
    }
    .floor3 {
      width: 94%;
      margin: 120px auto 20px;
      .content {
        width: 100%;
        padding: 40px 16px 20px;
        box-sizing: border-box;
        background: #622b83;
        border-radius: 22px;
        position: relative;
        .women {
          width: 111px;
          position: absolute;
          right: 20px;
          top: -100px;
        }
        .title {
          color: #cc3e91;
          font-weight: bold;
          font-size: 22px;
          margin-bottom: 16px;
          &.mt30 {
            margin-top: 30px;
          }
        }
        .txt {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #fefefe;
        }
      }
    }
  }
}
</style>
